
import { Component, OnInit } from '@angular/core';
import { CommercialService } from '../commercial.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.scss']
})
export class BrochureComponent implements OnInit  {
  route: ActivatedRouteSnapshot;

  constructor(
    private readonly commercialService: CommercialService,

    private location: Location,
    _route: ActivatedRoute,) {
      this.route = _route.snapshot

  }

  ngOnInit():void{
    this.route
    console.log("🚀 ~ BrochureComponent ~ ngOnInit ~  this.route:",  this.route.url[0].path)
    if ( this.route.url[0].path =='brochureAR') {
    console.log("🚀 ~ BrochureComponent ~ ngOnInit ~  this.route:",  'ARRR')
      this.downloadBrochureAR()
    }else{
    console.log("🚀 ~ BrochureComponent ~ ngOnInit ~  this.route:",  'FFFRRR')

      this.downloadBrochureFR()

    }

  }



  downloadBrochureAR() {
    this.commercialService.downloadBrochureAR().subscribe({
      next: (res) => {
        this.openFile(res, 'application/pdf');

        this.location.back();
      },

      error: (error) => {
        console.log("🚀 ~ DashboardComponent ~ this.commercialService.downloadBrochureAR ~ error:", error)
      }
    })
  }
  downloadBrochureFR() {
    this.commercialService.downloadBrochureFR().subscribe({
      next: (res) => {
        this.openFile(res, 'application/pdf');

        this.location.back();
      },

      error: (error) => {
        console.log("🚀 ~ DashboardComponent ~ this.commercialService.downloadBrochureAR ~ error:", error)
      }
    })
  }
  openFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your pop-up blocker and try again!')
    }
  }
}
