import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommercialService {

  constructor(private readonly http: HttpClient) { }


  getResumeStatistique(){
    return this.http.get(`${environment.apiV1}request/getResumeStatistique`, {
      responseType: 'blob',
    })
  }

  printCodeQRCODE(){
    return this.http.get(`${environment.apiV1}request/printCodeQRCODE`, {
      responseType: 'blob',
    })
  }

  printCodeQRCODEOffre(){
    return this.http.get(`${environment.apiV1}request/printCodeQRCODEOffre`, {
      responseType: 'blob',
    })
  }
  printCodeQRCODEBrochure(){
    return this.http.get(`${environment.apiV1}request/printCodeQRCODEBrochure`, {
      responseType: 'blob',
    })
  }
  printCodeQRCODEBrochureAR(){
    return this.http.get(`${environment.apiV1}request/printCodeQRCODEBrochureAR`, {
      responseType: 'blob',
    })
  }


  downloadBrochureAR(){
    console.log('service arrr')
    return this.http.get(`${environment.apiV1}public/downloadBrochureAR`, {
      responseType: 'blob',
    })
  }
  downloadBrochureFR(){
    return this.http.get(`${environment.apiV1}public/downloadBrochureFR`, {
      responseType: 'blob',
    })
  }
}
